.appClass{
    font-family: 'Verdana';
    text-align: center;
}
.topSpace{
    margin:10px auto;
}
#root{
    width: 100%; 
    height: 100%;
    display: flex;
    position: absolute; 
    justify-content: center;
    align-items: center;
    background: linear-gradient(90deg, rgba(245,93,62,1) 50%, rgba(118,190,208,1) 50%);
    /* background: linear-gradient(90deg, #011a27 50%, #063852 50%); */
}

.componentStyling{
    padding: 30px;
    border-radius: 30px;
    color: white;
}
.heading{
    background:  #F55D3E;
    
}
.darkheading{
    background:  #011a27;
}
.form{
    background: #76BED0;
}
.darkform{
    background: #063852;
}
.buttonSpace{
    margin: 10px;
}

.topleft{
    padding: 25px;
    position:absolute;
    top:0;
    left:0;
    border-bottom-right-radius: 25px;
    background: #FFA400;
    text-align: left;

}
.topleft h4{
    font-size: 15px;
}
.topright{
    padding: 25px;
    position:absolute;
    top:0;
    right:0;
    border-bottom-left-radius: 25px;
    background: #7caa2d;
    text-align: left;
}
.jcard{
    border-bottom: 4px solid white;
    margin-bottom: 15px;
}
.deserveform{
    margin:15px;
    font-style: italic;
    
}


